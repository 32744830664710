import {defineStore} from 'pinia'

import {isTest} from '@/utils/isTest'

export const useSettingsStore = defineStore({
  id: 'settings',
  state: () => ({
    settings: {},
  }),
  actions: {
    async getSettings() {
      try {
        if (this.settings && Object.keys(this.settings).length > 0) {
          return this.settings
        }
        const apiUrl = 'settings'
        if (isTest()) {
          return getUnitTestDataForStore(apiUrl)
        }
        const response = await $fetch(useRuntimeConfig().public.apiBaseUrl + '/' + apiUrl, {method: 'GET'})

        const settings = response?.data?.settings
        return settings
      } catch (error) {
        console.error('Error', error)
      }
    },
  },
})
